<template>
  <div>
    <button-all-header
      :contentBtnAdd="'Thêm người lao động'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideExportFile="false"
      class="button-table-worker"
      @clickDelete="deleteItems"
      @clickAdd="showModalWorker"
      @search="search($event)"
      @clickDowloadSample="downloadExampleFile"
      @importFile="importFileExcel($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataTable || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Mã đơn vị -->
        <span
          v-if="props.column.field === 'code'"
          class="text-nowrap"
        >
          <b-badge
            pill
            class="light-code"
          >
            <span class="text-name">{{ props.row.code }}</span>
          </b-badge>
        </span>

        <span
          v-else-if="props.column.field === 'isCerfiticate'"
          class="text-nowrap"
        >
          <feather-icon
            v-if="props.row.isCerfiticate"
            icon="CheckIcon"
            size="18"
            class="text-body"
          />
        </span>

        <span
          v-else-if="props.column.field === 'isSafeCard'"
          class="text-nowrap"
        >
          <feather-icon
            v-if="props.row.isSafeCard"
            icon="CheckIcon"
            size="18"
            class="text-body"
          />
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'actionFunction'">
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="text-body"
            @click="deleteItem(props.row.id)"
          />
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal thêm người lao động -->
    <modal-create-training
      :id="modalTrainingWorker"
      :dataInfo="dataInfo"
      :targetTrainingId="dataTargercode.id"
      @handleCreateTrainingWorker="handleCreateTrainingWorker"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreateTraining from './ModalCreateTraining.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    BDropdown,
    BDropdownItem,
    ConfirmModal,
    ButtonAllHeader,
    ModalCreateTraining,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    modeType: {
      type: String,
      default: '',
    },
    dataInfo: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      modalTrainingWorker: `confirm-training-workers-${this.modeType}`,
      formatFullName,
      columns: [
        {
          label: 'MÃ NGƯỜI LAO ĐỘNG',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN NGƯỜI LAO ĐỘNG',
          field: 'name',
          sortable: false,
        },
        {
          label: 'ĐƯỢC CẤP GIẤY CHỨNG NHẬN',
          field: 'isCerfiticate',
          sortable: false,
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
        {
          label: 'ĐƯỢC CẤP THẺ AN TOÀN',
          field: 'isSafeCard',
          sortable: false,
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
        {
          label: 'CHỨC NĂNG',
          field: 'actionFunction',
          sortable: false,
          width: '175px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
      ],
      currentPage: 0,
      urlQuery: {
        trainingId: this.$route.params.id,
        targetTrainingId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
        isSelfTraining: false,
      },
      confirmModalId: `'confirm-moda-${this.modeType}'`,
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      dataTable: [],
      totalRecord: 0,
      dataTargercode: {},
      arrayExcel: [
        'name',
        'isCerfiticate',
        'isSafeCard',
      ],
    }
  },
  created() {
    if (this.$route.params.id) {
      this.fetchListTrainingWorker(this.urlQuery)
    }
  },
  methods: {

    ...mapActions('performTraining', [
      'downloadExampleFile',
      'getApiExcel',
    ]),

    downloadExampleFile() {
      let nameFile = ''
      switch (this.modeType) {
        case 'NH01':
          nameFile = 'NguoiLaoDongThamGiaHuanLuyenNhom1.xlsx'
          break
        case 'NH02':
          nameFile = 'NguoiLaoDongThamGiaHuanLuyenNhom2.xlsx'
          break
        case 'NH03':
          nameFile = 'NguoiLaoDongThamGiaHuanLuyenNhom3.xlsx'
          break
        case 'NH04':
          nameFile = 'NguoiLaoDongThamGiaHuanLuyenNhom4.xlsx'
          break
        case 'NH05':
          nameFile = 'NguoiLaoDongThamGiaHuanLuyenNhom5.xlsx'
          break
        case 'NH06':
          nameFile = 'NguoiLaoDongThamGiaHuanLuyenNhom6.xlsx'
          break
        default:
          break
      }
      axiosApiInstance({
        url: '/Training/generate-template', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { targetTrainingId: this.dataTargercode.id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Lấy thông tin nhóm đối tượng theo mã
    async fetchListTargetCode() {
      const { data } = await axiosApiInstance.get(ConstantsApi.TARGET_TRAINING_CODE, { params: { code: this.modeType } })
      this.dataTargercode = data
    },

    // Lấy danh sách nhóm người dùng trong 1 người dùng
    async fetchListTrainingWorker(urlQuery) {
      this.$showLoading()
      await this.fetchListTargetCode()
      this.urlQuery.targetTrainingId = this.dataTargercode.id
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_TRAINING_WORKERS, { params: urlQuery })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListTrainingWorker(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchListTrainingWorker(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchListTrainingWorker(this.urlQuery)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        trainingId: this.$route.params.id,
        isSelfTraining: false,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_TRAINING_WORKERS, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListTrainingWorker(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Mở modal tạo yếu tố nguy hiểm có hại
    showModalWorker() {
      this.$bvModal.show(this.modalTrainingWorker)
    },

    // Thêm yếu tố nguy hiểm có hại
    async handleCreateTrainingWorker(val) {
      const payload = {
        ...val,
        trainingId: this.$route.params.id,
        isSelfTraining: false,
        targetTrainingId: this.dataTargercode.id,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_TRAINING_WORKERS, payload).then(response => {
        if (response.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalTrainingWorker)
        }
        this.fetchListTrainingWorker(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Thêm dữ liệu bằng file excel
    async importFileExcel(event) {
      if (event) {
        this.getValidata(event)
      }
    },
    async getValidata(dataInput) {
      const model = {
        listExcel: dataInput,
        trainingId: this.$route.params.id,
        targetTrainingId: this.dataTargercode.id,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({
        name: 'import-perform-training',
        params: {
          id: this.$route.params.id,
          dataInfo: this.dataInfo,
          dataTargercode: this.dataTargercode,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.text-name {
  color: #227ff4;
}

.light-code {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
}

.border-status {
  border-radius: 4px;
}

.button-table-worker {
  margin-left: -20px;
  margin-right: -20px;
}

.actiontd{
  text-align: center;
}
.actionth{
  text-align: center;
}
</style>
