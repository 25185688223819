<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm người lao động"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateTrainingWorker"
    @hidden="resetState"
    @show="fetchDataNotTraining"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-form-group>
          <label for="InputHelp">Người lao động<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="customCode"
          >
            <v-select
              v-model="dataInput.workerId"
              :reduce="item => item.id"
              :state="errors.length > 0 ? false:null"
              label="name"
              :options="dataNotTraining || []"
              placeholder="Lựa chọn người lao động"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="basicInput"
            >
              <b-form-checkbox
                id="checkbox-6"
                v-model="dataInput.isCerfiticate"
                name="checkbox-6"
              >Được cấp giấy chứng nhận</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="basicInput"
            >
              <b-form-checkbox
                id="checkbox-7"
                v-model="dataInput.isSafeCard"
                name="checkbox-7"
              >Được cấp thẻ an toàn</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormCheckbox,
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
  BRow,
  BCol,
  BForm,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    VSelect,
    DateTimePicker,
    BFormCheckbox,
    BFormTextarea,
    BRow,
    BCol,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataDetailAccidentWorker: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
    dataInfo: {
      type: Object,
      default: _ => {},
    },
    targetTrainingId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customCode: {
        required: 'Người lao động là bắt buộc',
      },
      required,
      dataInput: {
        workerId: '',
        isCerfiticate: false,
        isSafeCard: false,
      },
      urlQuery: {
        bussinessId: this.dataInfo.bussinessTrainingId,
        targetTrainingId: '',
      },
      dataNotTraining: [],
    }
  },
  methods: {

    // Danh sách người lao động huấn luyện
    async fetchDataNotTraining() {
      this.urlQuery.targetTrainingId = this.targetTrainingId
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_NOT_TRAINING_WORKERS, {
        params: this.urlQuery,
      })
      this.dataNotTraining = data?.data
    },

    // Tạo người lao động huấn luyện
    handleCreateTrainingWorker(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateTrainingWorker', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        workerId: '',
        isCerfiticate: false,
        isSafeCard: false,
      }
    },
  },
}
</script>
