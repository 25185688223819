<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="info-training"
      typeCss="active-full"
      @setActive="setActive"
    />

    <div class="page-container">
      <validation-observer ref="tabAccount">
        <info-training
          v-if="isRendered('info-training')"
          :dataInfo="dataInfo"
          :disableBussiness="disableBussiness"
          class="tab-pane"
          :class="{'active':activeTab==='info-training'}"
        />
        <table-group
          v-if="isRendered('group-one')"
          :key="1"
          :modeType="'NH01'"
          class="tab-pane"
          :dataInfo="dataInfo"
          :class="{'active':activeTab==='group-one'}"
        />
        <table-group
          v-if="isRendered('group-two')"
          :key="2"
          :modeType="'NH02'"
          class="tab-pane"
          :dataInfo="dataInfo"
          :class="{'active':activeTab==='group-two'}"
        />
        <table-group
          v-if="isRendered('group-three')"
          :key="3"
          :modeType="'NH03'"
          class="tab-pane"
          :dataInfo="dataInfo"
          :class="{'active':activeTab==='group-three'}"
        />
        <table-group
          v-if="isRendered('group-four')"
          :key="4"
          :modeType="'NH04'"
          class="tab-pane"
          :dataInfo="dataInfo"
          :class="{'active':activeTab==='group-four'}"
        />
        <table-group
          v-if="isRendered('group-five')"
          :key="5"
          :modeType="'NH05'"
          class="tab-pane"
          :dataInfo="dataInfo"
          :class="{'active':activeTab==='group-five'}"
        />
        <table-group
          v-if="isRendered('group-six')"
          :key="6"
          :modeType="'NH06'"
          class="tab-pane"
          :dataInfo="dataInfo"
          :class="{'active':activeTab==='group-six'}"
        />
      </validation-observer>
      <div
        class="mt-2"
      >
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="hadnleCreate($event)"
        >
          Lưu lại
        </b-button>
        <b-button
          variant="outline-primary"
          type="reset"
          @click="backUser"
        >
          Quay lại
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
}
  from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InfoTraining from './components/InfoTraining.vue'
import TableGroup from './components/TableGroup.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    MyTabs,
    ValidationObserver,
    ValidationProvider,
    InfoTraining,
    BButton,
    TableGroup,
  },
  data() {
    return {
      required,
      activeTab: null,
      listTabs: [
        {
          key: 'info-training',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'group-one',
          title: 'Nhóm 1',
          icon: 'UsersIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'group-two',
          title: 'Nhóm 2',
          icon: 'UsersIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'group-three',
          title: 'Nhóm 3',
          icon: 'UsersIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'group-four',
          title: 'Nhóm 4',
          icon: 'UsersIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'group-five',
          title: 'Nhóm 5',
          icon: 'UsersIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'group-six',
          title: 'Nhóm 6',
          icon: 'UsersIcon',
          isDisabled: true,
          isRendered: false,
        },
      ],
      dataInfo: {
        code: '',
        dateTraining: '',
        workerId: '',
        cost: '',
        bussinessId: '',
      },
      bussinessTrainingId: '',
      performId: null,
      dataPerformTrainingId: null,
      disableBussiness: null,
    }
  },
  watch: {
    dataPerformTrainingId(val) {
      if (val) {
        this.dataInfo = {
          code: this.dataPerformTrainingId.code,
          dateTraining: this.dataPerformTrainingId.dateTraining,
          workerId: this.dataPerformTrainingId.workerId,
          cost: this.dataPerformTrainingId.cost,
          bussinessId: this.dataPerformTrainingId.bussinessId,
          bussinessTrainingId: this.dataPerformTrainingId.bussinessTrainingId,
        }
        this.fetchDataComboboxAllWorker(this.dataPerformTrainingId.bussinessId)
      }
    },
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.performId = this.$route.params.id
      this.disableBussiness = true
      this.listTabs[1].isDisabled = false
      this.listTabs[2].isDisabled = false
      this.listTabs[3].isDisabled = false
      this.listTabs[4].isDisabled = false
      this.listTabs[5].isDisabled = false
      this.listTabs[6].isDisabled = false
      this.fetchPerformTrainingId(this.performId)
    }

    if (this.activeTab === null) {
      this.activeTab = 'info-training'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {
    ...mapActions('workerPermit', ['fetchDataComboboxAllWorker']),

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      const tabNot = this.listTabs.find(x => x.key === this.activeTab)
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) {
        tab.isRendered = true
        tabNot.isRendered = false
      }
    },
    // Lấy thông tin khai báo tai nạn lao động theo id
    async fetchPerformTrainingId() {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_PERFORM_TRAINING}${this.performId}`)
      this.dataPerformTrainingId = data
    },

    // Trở về danh sách
    backUser() {
      this.$router.push({ name: 'perform-training' })
    },

    // Action tạo khai báo tai nạn lao động
    async hadnleCreate(bvModalEvt) {
      if (this.$route.params.id) {
        const data = {
          ...this.dataInfo,
          id: this.$route.params.id,
          cost: parseInt(this.dataInfo.cost, 10),
          isSelfTraining: false,
        }
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.EDIT_PERFORM_TRAINING, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Cập nhật thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'perform-training' })
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      } else {
        const data = {
          ...this.dataInfo,
          cost: parseInt(this.dataInfo.cost, 10),
          isSelfTraining: false,
        }
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.CREATE_PERFORM_TRAINING, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Tạo thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.disableBussiness = true
                this.$router.push({ name: 'perform-training-edit', params: { id: res.data?.data?.id } })
                this.listTabs[1].isDisabled = false
                this.listTabs[2].isDisabled = false
                this.listTabs[3].isDisabled = false
                this.listTabs[4].isDisabled = false
                this.listTabs[5].isDisabled = false
                this.listTabs[6].isDisabled = false
                this.performId = res.data?.data?.id
                this.fetchPerformTrainingId(this.performId)
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      }
    },
  },
}
</script>
